import React, { useState } from "react";
import axios from "axios";
import Confetti from "react-confetti";
import {
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  ListItemText,
  CircularProgress,
  FormLabel,
  Radio,
} from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  titleDropdown,
  businessNatureDropdown,
  industryDropdown,
  categoryDropdown,
  productsDropdown,
  visitPurposeDropdown,
  showInfoDropdown,
} from "./constants"; // Import the dropdown options
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isEmail } from "validator";
import QRCode from "react-qr-code";
import CountrySelect from "./CountrySelect";
import may2024 from "./May-2024.png";

const NewForm = () => {
  const [formData, setFormData] = useState({
    title: ["Mr."],
    firstName: "",
    lastName: "",
    companyName: "",
    jobTitle: "",
    department: "",
    phoneNo: "",
    mobileNo: "",
    mobileCountryCode: "",
    email: "",
    website: "",
    city: "",
    nationality: "",
    businessNature: "",
    industry: "",
    category: [],
    products: [],
    visitPurpose: [],
    showInfo: "",
    isVisitedEarlier: false,
    isInterested: false,
  });
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countryError, setCountryError] = useState("");
  const [mobileNoError, setMobileNoError] = useState("");
  const [url, SetUrl] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleMultiSelectChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value === "true" }));
  };

  const handleCountryChange = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      city: selectedOption.label, // Use the label of the selected option as the city name
    }));
    setCountryError("");
  };
  const handlePhoneChange = (value, country) => {
    const countryCode = `+${country.dialCode}`;
    const phoneNumber = value.slice(country.dialCode.length);
    setFormData((prevData) => ({
      ...prevData,
      mobileNo: phoneNumber,
      mobileCountryCode: countryCode,
    }));
    setMobileNoError("");
  };
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };
  const isSubmitButtonEnabled =
    formData.acceptTerms && formData.acceptEmailInvitation;
  const validateEmail = () => {
    if (!formData.email) {
      return ""; // No error message if email field is empty
    }
    if (!isEmail(formData.email)) {
      return "Invalid email address";
    }
    return "";
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!formData.city) {
      setCountryError("Country is required");
      return; // Prevent form submission
    }
    if (!formData.mobileNo) {
      setMobileNoError("Mobile number is required");
      return; // Prevent form submission
    }
    setLoading(true);
    // Perform form submission logic, e.g., sending the form data to the server
    try {
      delete formData.acceptTerms;
      delete formData.acceptEmailInvitation;
      const dataToSend = {
        ...formData,
        category: formData.category.join(","),
        products: formData.products.join(","),
        visitPurpose: formData.visitPurpose.join(","),
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/submit`,
        dataToSend
      );
      // Reset the form fields
      setFormData({
        title: ["Mr."],
        firstName: "",
        lastName: "",
        companyName: "",
        jobTitle: "",
        department: "",
        phoneNo: "",
        mobileNo: "",
        mobileCountryCode: "",
        email: "",
        website: "",
        city: "",
        businessNature: "",
        industry: "",
        category: [],
        products: [],
        visitPurpose: [],
        showInfo: "",
        isVisitedEarlier: false,
        isInterested: false,
      });
      // Show the "Thank You" pop-up with confetti
      setSuccess(true);
      SetUrl(response?.data);
    } catch (error) {
      toast.error("An error occurred while submitting the form.");
      // Handle the error and show an error message to the user if necessary
    } finally {
      // Set loading back to false after the form submission is completed
      setLoading(false);
    }
  };

  return (
    <Grid container justifyContent="center">
      <Grid xs={12} sm={10} md={8} lg={6} xl={4}>
        <Paper
          style={{ padding: "2rem", margin: "10px auto", marginBottom: "80px" }}
        >
          {success ? (
            <>
              <Typography variant="h5" gutterBottom align="center">
                Thank You for Registering!
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  marginTop: "50px",
                }}
              >
                <QRCode
                  value={url}
                  size={128} // Adjust the size as needed
                  style={{ margin: "auto" }} // Center align the QR code
                />
                <div style={{ paddingTop: "20px", textAlign: "center" }}>
                  <Typography>
                    <span
                      style={{
                        fontWeight: "bold",
                        borderBottom: "2px solid red",
                      }}
                    >
                      Please present this QR code to the registration desk upon
                      your arrival at the fair, as it will facilitate your
                      seamless entry. Your cooperation in this matter is
                      sincerely appreciated.
                    </span>{" "}
                  </Typography>
                  <Typography style={{ paddingTop: "10px" }}>
                    As a form of guidance, we kindly advise you to capture a
                    screenshot of this QR code and store it in your image
                    gallery for your ease of access.
                  </Typography>
                  <Typography style={{ paddingTop: "10px" }}>
                    Furthermore, we wish to inform you that this identical QR
                    code can also be located within your registered email
                    account.
                  </Typography>
                  <Typography
                    style={{ textAlign: "center", paddingTop: "10px" }}
                  >
                    <img src={may2024} alt="date" height="140px" />
                    <br />
                    <b>FREE ENTRY</b>
                    <br />
                    <b>1500+ FREE PARKINGS</b>
                    <br />
                    <b>EXCLUSIVE BUYERS LOUNGE</b>
                  </Typography>
                </div>
              </div>
              <Confetti />
            </>
          ) : (
            <div style={{ position: "relative", opacity: loading ? 0.7 : 1 }}>
              <Typography variant="h5" gutterBottom>
                Registration For Event
              </Typography>
              <form onSubmit={handleFormSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Title</InputLabel>
                      <Select
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                        required
                      >
                        {titleDropdown.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="firstName"
                      label="First Name"
                      value={formData.firstName}
                      onChange={handleChange}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="lastName"
                      label="Family Name"
                      value={formData.lastName}
                      onChange={handleChange}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="companyName"
                      label="Company Name"
                      value={formData.companyName}
                      onChange={handleChange}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="jobTitle"
                      label="Job Title"
                      value={formData.jobTitle}
                      onChange={handleChange}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="department"
                      label="Department"
                      value={formData.department}
                      onChange={handleChange}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="phoneNo"
                      label="Phone No."
                      value={formData.phoneNo}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabel required>Mobile No</InputLabel>
                    <PhoneInput
                      name="mobileNo"
                      label="Mobile No."
                      value={`${formData.mobileCountryCode}${formData.mobileNo}`}
                      onChange={handlePhoneChange}
                      inputStyle={{
                        borderColor: formData.mobileNoError && "red",
                        width: "278px",
                      }}
                      country={"ae"}
                      enableAreaCodes={true}
                      countryCodeEditable={false}
                      style={{ width: "200px" }}
                    />
                    {mobileNoError && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginTop: "8px",
                        }}
                      >
                        {mobileNoError}
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="email"
                      label="Email"
                      value={formData.email}
                      onChange={handleChange}
                      onBlur={validateEmail}
                      error={validateEmail() !== ""}
                      helperText={validateEmail()}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="website"
                      label="Website"
                      value={formData.website}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel style={{ marginBottom: "8px" }} required>
                      Country
                    </InputLabel>
                    <FormControl fullWidth>
                      <CountrySelect
                        selectedCountry={formData.city}
                        setSelectedCountry={handleCountryChange}
                      />
                      {countryError && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginTop: "8px",
                          }}
                        >
                          {countryError}
                        </p>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="nationality"
                      label="Nationality"
                      value={formData.nationality}
                      onChange={handleChange}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Business Nature of Company*</InputLabel>
                      <Select
                        name="businessNature"
                        value={formData.businessNature}
                        onChange={handleChange}
                        required
                      >
                        {businessNatureDropdown.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Your Current Industry</InputLabel>
                      <Select
                        name="industry"
                        value={formData.industry}
                        onChange={handleChange}
                      >
                        {industryDropdown.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Category</InputLabel>
                      <Select
                        name="category"
                        multiple
                        value={formData.category}
                        onChange={handleMultiSelectChange}
                        renderValue={(selected) => selected.join(", ")}
                      >
                        {categoryDropdown.map((option) => (
                          <MenuItem key={option} value={option}>
                            <Checkbox
                              checked={formData.category.includes(option)}
                            />
                            <ListItemText primary={option} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Products of Interest</InputLabel>
                      <Select
                        name="products"
                        multiple
                        value={formData.products}
                        onChange={handleMultiSelectChange}
                        renderValue={(selected) => selected.join(", ")}
                        required
                      >
                        {productsDropdown.map((option) => (
                          <MenuItem key={option} value={option}>
                            <Checkbox
                              checked={formData.products.includes(option)}
                            />
                            <ListItemText primary={option} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Purpose of Visit</InputLabel>
                      <Select
                        name="visitPurpose"
                        multiple
                        value={formData.visitPurpose}
                        onChange={handleMultiSelectChange}
                        renderValue={(selected) => selected.join(", ")}
                        required
                      >
                        {visitPurposeDropdown.map((option) => (
                          <MenuItem key={option} value={option}>
                            <Checkbox
                              checked={formData.visitPurpose.includes(option)}
                            />
                            <ListItemText primary={option} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>
                        How did you find out about the show?
                      </InputLabel>
                      <Select
                        name="showInfo"
                        value={formData.showInfo}
                        onChange={handleChange}
                      >
                        {showInfoDropdown.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Did you visit IATF last edition?
                      </FormLabel>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Yes"
                          name="isVisitedEarlier"
                          checked={formData.isVisitedEarlier === true}
                          onChange={handleRadioChange}
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="No"
                          name="isVisitedEarlier"
                          checked={formData.isVisitedEarlier === false}
                          onChange={handleRadioChange}
                        />
                      </div>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Are you interested to exhibit in the next edition?
                      </FormLabel>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Yes"
                          name="isInterested"
                          checked={formData.isInterested === true}
                          onChange={handleRadioChange}
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="No"
                          name="isInterested"
                          checked={formData.isInterested === false}
                          onChange={handleRadioChange}
                        />
                      </div>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="acceptTerms"
                              checked={formData.acceptTerms}
                              onChange={handleCheckboxChange}
                            />
                          }
                          label={
                            <Typography>
                              I <b>UNDERSTAND AND ACCEPT</b> that my personal
                              data identified above will be processed by
                              Nihalani Events for the purpose of registration
                              and registration in the IATF event.
                            </Typography>
                          }
                        />
                      </div>
                    </FormControl>
                    <FormControl component="fieldset">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="acceptEmailInvitation"
                              checked={formData.acceptEmailInvitation}
                              onChange={handleCheckboxChange}
                            />
                          }
                          label={
                            <Typography style={{ marginTop: "5px" }}>
                              I <b>UNDERSTAND AND ACCEPT</b> that my personal
                              data “email” & “contact number” will be processed
                              by Nihalani Events for the purpose of sending an
                              invitation to the next edition of the IATF event.
                            </Typography>
                          }
                        />
                      </div>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={!isSubmitButtonEnabled}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          )}
          {loading && (
            <CircularProgress
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          )}
        </Paper>
        <ToastContainer />
      </Grid>
    </Grid>
  );
};

export default NewForm;
